import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';

import SectionProductsList from '@components/common/SectionList/productsList';
import { IHomePageComponentDataObject } from '@interfaces/home';
import { IProductColor, IProductInList } from '@interfaces/product';
import { IStore } from '@interfaces/store';
import { defaultComponent } from './constants';
import { IHomepageProductsSliderComponent, IHomepageProductsSliderItem } from './interfaces';

export interface IHomepageProductsSliderProps {
  component: IHomePageComponentDataObject;
}

const HomepageProductsSlider = (props: IHomepageProductsSliderProps) => {

  const component: IHomepageProductsSliderComponent = { ...defaultComponent, ...props.component };
  const productItems: IHomepageProductsSliderItem[] = component.productids || [];

  const homePageProducts = useSelector((state: IStore) => state.common.homePageProducts);
  const loaded = useSelector((state: IStore) => state.common.loaded.homePageProducts);

  const productIds = useMemo(() => productItems.map((item: IHomepageProductsSliderItem) => {
    if (typeof item === 'number') {
      return item;
    }
    return item.id || 0;
  }), [productItems]);


  const findProductId = (colors: IProductColor[]): number => {
    if (colors.length === 0) {
      return 0;
    }

    const color = colors.find(({ productId }) => productIds.includes(productId));
    return color ? color.productId : 0;
  };

  const sortProducts = (a: IProductInList, b: IProductInList): number => {
    const aProductId = findProductId(a.colors);
    const bProductId = findProductId(b.colors);
    if (!aProductId || !bProductId) {
      return 0;
    }

    return productIds.indexOf(aProductId) - productIds.indexOf(bProductId);
  };

  const replaceFirstImage = (item: IProductInList, newImage: string): IProductInList => {
    const first = item.colors?.[0]?.pictures?.[0];
    if (first) {
      const newPicture = {
        ...first,
        urlMedium: newImage,
      };
      item.colors[0].pictures.splice(0, 1, newPicture);
    }
    return item;
  };
  const sortedProducts: IProductInList[] = homePageProducts.slice().sort(sortProducts);
  const mappedProducts: IProductInList[] = sortedProducts.map((item: IProductInList) => {
    const productId = findProductId(item.colors);
    const index = productIds.indexOf(productId);
    const productItem: IHomepageProductsSliderItem = productItems[index];
    if (productItem && typeof productItem === 'object' && productItem.media) {
      return replaceFirstImage(item, productItem.media);
    }
    return item;
  });

  return <SectionProductsList
    loaded={loaded}
    products={mappedProducts}
    wrapperListName={component.heading} />;
};

export default HomepageProductsSlider;
