import React, { useCallback, useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { acGetHomePageProductsProductsAction } from '@actions/acCommon';

import Section from '@components/common/Section';
import HomepageProductsSlider from '@components/pages/home/products-slider/HomepageProductsSlider';

import { IHomePageComponentDataObject } from '@interfaces/home';
import { TThunkDispatch } from '@interfaces/index';
import { IHomepageProductsSliderItem } from '@components/pages/home/products-slider/interfaces';
import { IStore } from '@interfaces/store';

interface ISectionProps {
  component: IHomePageComponentDataObject;
  className?: string;
}

const HomePageProductsSlider: React.FunctionComponent<ISectionProps> = ({ component, className }) => {
  const dispatch = useDispatch<TThunkDispatch>();
  const loading = useSelector((state: IStore) => state.common.loading.homePageProducts);

  const countryCode = useSelector((state: IStore) => state.pageData.countryCode);
  const languageCode = useSelector((state: IStore) => state.pageData.languageCode);

  const productItems: IHomepageProductsSliderItem[] = component.productids || [];

  const productIds = useMemo(() => productItems.map((item: IHomepageProductsSliderItem) => {
    if (typeof item === 'number') {
      return item;
    }
    return item.id || 0;
  }), [productItems]);

  useEffect(() => {
      if (!loaded && !!productIds.length && !loading) {
        onGetHomePageProducts(productIds);
      }
  }, [countryCode, languageCode, productIds]);

  const onGetHomePageProducts = useCallback((productId) => dispatch(acGetHomePageProductsProductsAction(productId)), [dispatch]);

  const homePageProductsLength = useSelector((state: IStore) => state.common.homePageProducts?.length);
  const loaded = useSelector((state: IStore) => state.common.loaded.homePageProducts);
  const isItemsExist = loaded && homePageProductsLength > 0;

  return isItemsExist ? (
      <Section className={className} title={component?.heading || ''}
               showGenderCategories={false}
               showViewAllButton={false}
      >
        <HomepageProductsSlider component={component} />
      </Section>
  ) : null;
};

export default HomePageProductsSlider;
